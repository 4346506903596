import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

let searchBox;
const SboxContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 70%;
`
const InputSearch = styled.input`
  font-size: .7rem;
  width: 100%;
  padding-left: 1.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow:hidden;
`
const Smarker = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height="25" width="25" style={{position:'absolute',top:'4px',left:'5px'}}>
    <path d="M19 3C13.489 3 9 7.489 9 13C9 15.395 9.839 17.5875 11.25 19.3125L3.28125 27.28125L4.71875 28.720703L12.6875 20.751953C14.4115 22.161953 16.605 23 19 23C24.511 23 29 18.511 29 13C29 7.489 24.511 3 19 3 z M 19 5C23.43 5 27 8.57 27 13C27 17.43 23.43 21 19 21C14.57 21 11 17.43 11 13C11 8.57 14.57 5 19 5 z M 19 8C16.791 8 15 9.791 15 12C15 15 19 19 19 19C19 19 23 15 23 12C23 9.791 21.209 8 19 8 z M 19 10C20.105 10 21 10.895 21 12C21 13.104 20.105 14 19 14C17.895 14 17 13.104 17 12C17 10.895 17.895 10 19 10 z" fill="#767676" />
  </svg>
)
const SearchBox = (props) => {
  const { onPlacesChanged } = props;
  const inputRef = useRef(null);

  const handlePlacesChanged = () => {
    if (onPlacesChanged) {
      onPlacesChanged(searchBox?.getPlaces());
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react/no-find-dom-node
    const input = ReactDOM.findDOMNode(inputRef.current);
    searchBox = new google.maps.places.SearchBox(input);
    searchBox.addListener('places_changed', handlePlacesChanged);

    return () => {
      google.maps.event.clearInstanceListeners(searchBox);
    };
  }, []);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <SboxContainer style={{position:'absolute',top: '10px',left:'10px'}}>
      <InputSearch ref={inputRef} {...props} type="text" />
      <Smarker />
    </SboxContainer>
    );
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  onPlacesChanged: PropTypes.func,
};

export default SearchBox;